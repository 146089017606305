import { css } from 'styled-components';

export enum Breakpoints {
  fallback = 'fallback',
  mobile = 'mobile',
  tablet = 'tablet',
  desktop = 'desktop',
  tv = 'tv',
}

type MediaBreakpoints = {
  [key in Breakpoints]: number;
};

export const mediaBreakpoints: MediaBreakpoints = {
  [Breakpoints.fallback]: 0,
  [Breakpoints.mobile]: 624,
  [Breakpoints.tablet]: 1024,
  [Breakpoints.desktop]: 1440,
  [Breakpoints.tv]: 1800,
};

type Media = {
  [key in Breakpoints]: typeof css;
};

export const media: Media = Object.values(Breakpoints).reduce((acc, label) => {
  /* eslint-disable  @typescript-eslint/no-explicit-any */
  acc[label] = (first: any, ...args: any[]) => css`
    @media (max-width: ${mediaBreakpoints[label]}px) {
      ${css(first, ...args)}
    }
  `;
  return acc;
},
{} as Media);
